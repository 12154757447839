export const FirebaseConfig = {
	"projectId": "taxi-jungle",
	"appId": "1:320499262502:web:fada9dc0be38ecd8fb72e7",
	"databaseURL": "https://taxi-jungle-default-rtdb.firebaseio.com",
	"storageBucket": "taxi-jungle.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCwITM3YmQX0b4dZlpzurG7_G8p8gkL0kw",
	"authDomain": "taxi-jungle.firebaseapp.com",
	"messagingSenderId": "320499262502",
	"measurementId": "G-K2E25BMPZQ"
};